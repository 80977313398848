//
// Component: GameSummary
//

import React from 'react'
import Modal from 'react-modal'
import { observer } from 'mobx-react'
import { Flex, Box, Button } from 'rebass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// stores
import GameStore from 'App/stores/GameStore'

// Components
import H1 from 'App/components/text/H1.js'
import Text from 'App/components/text/Text.js'

@observer
class GameSummary extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      resultsHidden: false
    }
    this.selectScenario = this.selectScenario.bind(this)
    this.replayScenario = this.replayScenario.bind(this)
    // this.replayScenarioShowAll = this.replayScenarioShowAll.bind(this)
    this.state.customStyles = {}
  }

  selectScenario () {
    // Make out that Select a Scenario from the menu has been clicked
    this.props.menuClicked(1)
  }

  replayScenario () {
    this.props.replayScenario()
  }

  // replayScenario () {
  //   // Start this scenario again
  //   this.props.dealHands(GameStore.selectedScenario)
  // }

  /**
   *
   * Read Solution button has been pressed
   */

  readSolution () {
    var selectedScenario = GameStore.allScenarios.data.find(item => item.id === parseInt(GameStore.selectedScenario))
    alert(selectedScenario.solution)
  }

  // replayScenarioShowAll () {
  //   // Start this scenario again but show all hands
  //   GameStore.makeHandsVisible()
  //   this.props.dealHands(GameStore.selectedScenario)
  // }

  hideResults () {
    this.setState({
      resultsHidden: true,
      customStyles: {
        overlay: {
          background: 'none'
        },
        content: {
          top: '95%',
          height: '25px',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          overflow: 'hidden',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)'
        }
      }
    })
  }

  showResults () {
    this.setState({
      customStyles: {},
      resultsHidden: false
    })
  }

  render () {
    if (GameStore.bid) {
      var bidDenomination
      switch (GameStore.bid.denomination) {
        case 'spades':
          bidDenomination = <FontAwesomeIcon icon={['fas', 'spade']} />
          break
        case 'hearts':
          bidDenomination = <FontAwesomeIcon icon={['fas', 'heart']} />
          break
        case 'diamonds':
          bidDenomination = <FontAwesomeIcon icon={['fas', 'diamond']} />
          break
        case 'clubs':
          bidDenomination = <FontAwesomeIcon icon={['fas', 'club']} />
          break
        case 'notrumps':
          bidDenomination = 'NT'
          break
      }
    }

    var suitName = {
      S: 'spade',
      H: 'heart',
      D: 'diamond',
      C: 'club'
    }

    var bidSuit = suitName[GameStore.bid.denomination]

    var declarer = GameStore.declarer
    if (declarer) {
      declarer = declarer.toUpperCase()
    }

    var declarerTricks = 0
    if (['north', 'south'].includes(declarer.toLowerCase())) {
      declarerTricks = GameStore.tricksWonByPartners.northsouth
    } else {
      declarerTricks = GameStore.tricksWonByPartners.eastwest
    }

    var resultStr = '?'
    var bidLevel = parseInt(GameStore?.bid?.level)
    var bidTotal = bidLevel + 6
    if (declarerTricks) {
      if (bidTotal === declarerTricks) {
        resultStr = '='
      } else {
        const total = declarerTricks - bidTotal
        resultStr = total > 0 ? `+${total}` : total
      }
    }

    return (
      <Modal
        isOpen={this.props.showModal}
        ariaHideApp={false} // See http://reactcommunity.org/react-modal/accessibility/
        // eslint-disable-next-line react/jsx-handler-names
        // eslint-disable-next-line react/jsx-props-no-multi-spaces
        onRequestClose={this.props.closeWithOverlay ? this.props.closeModal : undefined}
        closeTimeoutMS={2000}
        className='modal'
        overlayClassName='modalOverlay'
        style={this.state.customStyles}
      >

        {this.state.resultsHidden &&
          <Flex
            px={3}
            width={1}
          >
            <Button
              mb={20}
              width={1}
              textAlign='center'
              fontWeight='700'
              textTransform='uppercase'
              backgroundColor='#46282C'
              fontSize='14px,'
              sx={{
                borderRadius: 0,
                cursor: 'pointer',
                fontSize: '14px'
              }}
              // eslint-disable-next-line react/jsx-handler-names
              onClick={() => { this.showResults() }}
            >
              SHOW RESULTS
            </Button>
          </Flex>}

        <Box
          px={3}
          width={1}
        >
          <H1 textAlign='center'>POST GAME SUMMARY</H1>
        </Box>
        <Flex
          sx={{
            mb: '10px'
          }}
        >
          <Box
            px={3}
            py={1}
            mx={2}
            my={1}
            width={1 / 2}
            textAlign='center'
            color='white'
            bg='#9d2725'
            fontSize={2}
            lineHeight='24px'
          > CONTRACT
          </Box>
          <Box
            px={3}
            py={1}
            mx={2}
            my={1}
            width={1 / 2}
            textAlign='center'
          >
            <Text className={`color-${bidSuit}`}>
              {GameStore.bid ? GameStore.bid.level : ''} {bidDenomination} by {declarer}
            </Text>
          </Box>
        </Flex>
        <Flex
          sx={{
            borderBottom: '1px solid #000',
            mb: '10px'
          }}
        >
          <Box
            px={3}
            py={1}
            mx={2}
            my={1}
            width={1 / 2}
            textAlign='center'
            color='white'
            bg='#9d2725'
            fontSize={2}
            lineHeight='24px'
          >
            RESULT
          </Box>
          <Box
            px={3}
            py={1}
            mx={2}
            my={1}
            width={1 / 2}
            textAlign='center'
          >
            <Text>
              {resultStr}
            </Text>
          </Box>
        </Flex>
        <Flex
          sx={{
            borderBottom: '1px solid #000',
            mb: '10px'
          }}
        >
          <Box
            px={3}
            py={1}
            mx={2}
            my={1}
            width={1 / 2}
            textAlign='center'
            color='white'
            bg='#9d2725'
            fontSize={2}
            lineHeight='24px'
          >
            SCORE
          </Box>
          <Box
            px={3}
            py={1}
            mx={2}
            my={1}
            width={1 / 2}
            textAlign='center'
          >
            <Text>
              {GameStore.score}
            </Text>
          </Box>
        </Flex>
        <Flex>
          <Box
            px={3}
            py={1}
            mx={2}
            my={1}
            width={1 / 2}
            textAlign='center'
            color='white'
            bg='#9d2725'
          >
            <Text fontSize={[2]}>NORTH/SOUTH</Text>
          </Box>
          <Box
            px={3}
            py={1}
            mx={2}
            my={1}
            width={1 / 2}
            textAlign='center'
            color='white'
            bg='#9d2725'
          >
            <Text fontSize={[2]}>EAST/WEST</Text>
          </Box>
        </Flex>
        <Flex
          sx={{
            borderBottom: '1px solid #000',
            mb: '10px'
          }}
        >
          <Box
            p={2}
            width={1 / 2}
            textAlign='center'
            fontWeight='700'
          >
            <Text>{GameStore.tricksWonByPartners.northsouth}/13</Text>
          </Box>
          <Box
            p={2}
            width={1 / 2}
            textAlign='center'
            fontWeight='700'
          >
            <Text>{GameStore.tricksWonByPartners.eastwest}/13</Text>
          </Box>
        </Flex>
        <Flex
          sx={{
            mb: '10px'
          }}
        >
          <Button
            px={3}
            py={1}
            mx={2}
            my={1}
            width={1 / 2}
            textAlign='center'
            fontWeight='700'
            textTransform='uppercase'
            backgroundColor='#46282C'
            fontSize='14px,'
            sx={{
              borderRadius: 0,
              cursor: 'pointer',
              fontSize: '14px'
            }}
            // eslint-disable-next-line react/jsx-handler-names
            onClick={this.selectScenario}
          >
            PLAY NEW GAME
          </Button>

          <Button
            px={3}
            py={1}
            mx={2}
            my={1}
            width={1 / 2}
            textAlign='center'
            fontWeight='700'
            textTransform='uppercase'
            backgroundColor='#46282C'
            fontSize='14px,'
            sx={{
              borderRadius: 0,
              cursor: 'pointer',
              fontSize: '14px'
            }}
            // eslint-disable-next-line react/jsx-handler-names
            onClick={() => { this.hideResults() }}
          >
            HIDE RESULTS
          </Button>
          {this.props.primaryPlayer &&
            <Button
              px={3}
              py={1}
              mx={2}
              my={1}
              width={1 / 2}
              textAlign='center'
              fontWeight='700'
              textTransform='uppercase'
              backgroundColor='#46282C'
              fontSize='14px,'
              sx={{
                borderRadius: 0,
                cursor: 'pointer',
                fontSize: '14px'
              }}
              // eslint-disable-next-line react/jsx-handler-names
              onClick={this.replayScenario}
            >
              TRY AGAIN
            </Button>}
        </Flex>
      </Modal>
    )
  }
}
export default GameSummary
